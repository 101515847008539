/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import { Link } from 'gatsby'

import { FaHome } from 'react-icons/fa'

import { Container } from '../Container'
import { FullScreenSection } from '../FullScreenSection'

import { masthead, mastheadActive, mastheadInner, mastheadNav } from './Masthead.module.scss'

interface MastheadLinkProps extends React.HTMLProps<HTMLLinkElement> {
  to: string
}

const MastheadLink: React.SFC<MastheadLinks> = ({ to, children }) => (
  <Link className={(typeof window !== typeof undefined && window.location.pathname == to) ? mastheadActive : mastheadNav} to={to} offset={-60}>
    {children}
  </Link>
)

export const Masthead: React.SFC = () => (
  <header className={masthead}>
    <Container>
      <div className={mastheadInner}>
        <nav className={mastheadNav}>
          <MastheadLink to="/"><FaHome /> Home</MastheadLink>
        </nav>
        <nav className={mastheadNav}>
	  <MastheadLink to="/about">About</MastheadLink>
	  <MastheadLink to="/files">Files</MastheadLink>
          <MastheadLink to="/links">Links</MastheadLink>
        </nav>
      </div>
    </Container>
  </header>
)
